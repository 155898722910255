import React from 'react';

function Electric() {
    return (
      <div className="container">
      <section className="image" style={{  
        backgroundImage: `url(${process.env.PUBLIC_URL + '/Tech3/EP.webp'})`,
      }}>
      </section>
      <div>ㅤ</div>
      <div>ㅤ</div>
    </div>
    );
  }
  
  export default Electric;