import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "새로 수정한 문구" : "새로 수정한 문구의 영어 번역", 
      "에덴동산과 같은 최적의 환경을 이 땅에 ㈜에덴이 만듭니다":"Creates an ideal environment on this land, just like the Garden of Eden.",
      "회사소개": "Company Introduction",
      "기술분야": "Technical Areas",
      "R&D": "R&D",
      "환경뉴스": "Environmental News",
      "견적의뢰": "Request Quote",
      "인사말": "Greeting",
      "회사연혁": "Company History",
      "특허 및 인증서": "Patents and Certifications",
      "수질": "Water Quality",
      "대기": "Air Quality",
      "제품소개": "Product Introduction",
      "UV소독기": "UV Sterilizer",
      "전기집진기": "Electrostatic Precipitator",
      "링크 컨베이어": "Link Conveyor",
      "후처리필터": "Post-Treatment Filter",
      "토목공사": "Civil Engineering",
      "수처리시설 설계": "Design",
      "수처리시설 시공": "Construction",
      "수처리시설 관리": "Management",
      "대기오염 방지시설 설계": "Design",
      "대기오염 방지시설 시공": "Construction",
      "대기오염 방지시설 관리": "Management",
      "토목공사업": "Civil Engineering",
      "상하수도설비공사업": "Water&Sewage Construction",
      "기계설비공사업": "Machinery Construction",
      "㈜에덴은  지구환경이 에덴동산과 같이 아름답고 살기에 좋은 환경이 되는 비전을 가지고": "Eden Co. envisions a world where the environment is as beautiful and livable as Eden.",
      "우리에게 주어진 능력과 사명으로 최적의 환경서비스를 제공하도록 노력하겠습니다.": "We strive to provide optimal environmental services with our given abilities and mission.",
      "㈜에덴은 환경분야에서 오랜 업력을 바탕으로 많은 경험과 신기술 특허를 보유하여 기술성과 전문성을 인정받은 회사입니다.": "Eden Co. is recognized for its technology and expertise, based on years of experience and numerous new technology patents in the environmental field.",
      "미래 세대가 안전하고 깨끗한 환경에서 생활하도록 기여하는 회사로 발전할 것입니다.": "We will grow into a company that contributes to future generations living in a safe and clean environment.",
      "서울특별시 송파구 충민로 52, 가든파이브웍스 D동 607호": "52 Chungmin-ro, Garden5 Works D-607, Songpa-gu, Seoul",
      "경기도 평택시 서탄면 마두1길 170-38, 1동": "170-38 Madu 1-gil, Seotan-myeon, Pyeongtaek-si, Gyeonggi-do",
      "본사": "Head Office",
      "연구소": "Research Institute",
      "Tel": "Tel",
      "Fax": "Fax",
      "Email": "Email",
      "견적 요청이 성공적으로 제출되었습니다!": "Request has been successfully submitted!",
      "견적 요청 제출에 실패했습니다.": "Failed to submit the request.",
      "견적 의뢰": "Request Quote",
      "제목을 입력해주세요.": "Please enter a subject.",
      "이름을 입력해주세요.": "Please enter your name.",
      "휴대폰 번호를 입력해주세요.": "Please enter your phone number.",
      "연락 받으실 이메일 주소를 입력해주세요.": "Please enter your email address.",
      "내용을 입력해주세요.": "Please enter your message.",
      "제출": "Submit",
      "R&D 연구개발": "R&D Research and Development",
      "수행기간": "Project Duration",
      "연구과제명": "Research Project Name",
      "부처명": "Department",
      "역할": "Role",
      "시작일": "Start Date",
      "종료일": "End Date",
      "EM-SYSTEM을 이용한 하, 폐수 고도처리시설(EM-SBR) 개발": "Development of EM-SBR, Advanced Wastewater Treatment Facility Using EM-SYSTEM",
      "야질산화-아탈질을 이용한 질소/인 동시제거 SBR 시스템 개발": "Development of SBR System for Simultaneous Nitrogen/Phosphorus Removal Using Anaerobic Ammonium Oxidation-Denitrification",
      "음식점 대기시설 개선 시범사업": "Pilot Project for Improving Air Facilities in Restaurants",
      "직화구이 음식점에서 미세먼지 제거를 위한 전기집진장치 개발": "Development of Electrostatic Precipitator for Removing Fine Dust in Charcoal Grill Restaurants",
      "직화구이 음식점 입자상물질(TSP) 및 악취물질 동시처리장치 개발": "Development of Device for Simultaneous Treatment of Total Suspended Particles (TSP) and Odor in Charcoal Grill Restaurants",
      "미세먼지 배출저감 및 위험회피 R&D 및 실증": "R&D and Demonstration for Fine Dust Emission Reduction and Risk Avoidance",
      "해외플랜트 시장개척 지원사업: 베트남, 박닌성 3,000톤/일 폐수처리 플랜트 공정설비": "Overseas Plant Market Development Support Project: Vietnam, Bac Ninh Province 3,000 tons/day Wastewater Treatment Plant Process Equipment",
      "서울시 인쇄소 휘발성유기화합물(VOCs) 저감시설 시범설치 및 저감방안": "Pilot Installation and Reduction Plan for VOCs Reduction Facilities in Seoul Printing Shops",
      "자연유하 방식 정화조의 하수악취 저감 방안 실증 용역": "Demonstration Service for Sewage Odor Reduction Measures in Gravity-Flow Septic Tanks",
      "참여기업": "Participating Company",
      "주관기업": "Lead Company",
      "협력기관": "Cooperative Organization",
      "주관기관": "Lead Organization",
      "㈜에덴은 에덴동산과 같은 안전하고 깨끗한 환경을": "Eden Co., Ltd. is committed to providing a safe and clean environment,",
      "이 땅의 미래 세대에게 제공하기 위해 앞장서고 있습니다.": "like the Garden of Eden, for future generations on this earth.",
      "특허 보유 수": "Number of Patents",
      "2024.01.01 기준": "As of 2024.01.01",
      "사업자 등록증 및 보유면허 및 인증현황": "Business Registration Certificate and Held Licenses and Certifications",
      "특 허": "Patent",
      "㈜에덴은 꾸준한 개발로 더 나은 환경을 만들어나갑니다.": "Eden Co., Ltd. continuously develops to create a better environment.",
      "그 동안 축적된 설계 및 시공경험과 기술력을 바탕으로 꾸준한 기술개발을 통해": "With our accumulated experience and technical expertise,",
      "각종 지식재산권을 보유하고 있습니다. 또한 해외 및 미래 시장에서": "we hold various intellectual property rights and strive",
      "기술의 자립 및 선도를 목표로 지속적인 연구개발을 추진하고 있습니다.": "for technological leadership in future markets.",
      "공사 실적": "Construction Performance",
      "계약일": "Contract Date",
      "공사명": "Project Name",
      "발주처": "Client",
      "㈜에덴은 첨단 수질 관리 솔루션을 통해 오염된 물을": "Eden Co., Ltd. purifies polluted water through advanced water quality management solutions",
      "깨끗하게 정화하여 건강한 수자원을 보존하는 데 기여하고 있습니다.": "and contributes to preserving healthy water resources.",
      "우리 회사는 다양한 수질 정화 기술을 활용하여 산업 폐수, 생활 하수 등을 효과적으로 처리하고 있으며,": "Our company effectively treats industrial wastewater, domestic sewage, and more using various water purification technologies,",
      "이를 통해 깨끗한 물 환경을 유지하는 데 최선을 다하고 있습니다.": "making every effort to maintain a clean water environment.",
      "동일면 대영,봉남마을 하수도 정비사업(3차분) 공법지자재 구매": "Purchase of materials for sewer maintenance project in Daeyoung and Bongnam villages, Dongil-myeon (3rd phase)",
      "광주지방조달청(고흥군)": "Gwangju Regional Procurement Office (Goheung-gun)",
      "한겨레고 오수정화조 개선공사": "Improvement of wastewater purification tank at Hangyere High School",
      "경기도 교육청 한겨례고등학교": "Gyeonggi-do Office of Education Hangyere High School",
      "FRP오수처리시설 제작 및 납품": "Manufacture and delivery of FRP wastewater treatment facility",
      "㈜파인우드": "Pinewood Co., Ltd.",
      "예산시험장 오폐수처리시설 보수공사": "Repair work of wastewater treatment facility at Yesan Test Site",
      "도로교통공단 예산운전면허시험장": "Korea Road Traffic Authority Yesan Driver's License Test Site",
      "수도병원 자동제어설비 교체공사": "Replacement of automatic control equipment at the Capital Hospital",
      "국군의무사령부": "Armed Forces Medical Command",
      "무안 항공특화산업단지 조성공사 하수처리시설 기자재 납품 설치공사": "Delivery and installation of wastewater treatment facility equipment for Muan Aviation Specialized Industrial Complex construction project",
      "㈜예진종합건설": "Yejin General Construction Co., Ltd.",
      "FRP 오수처리시설 제작 및 납품(3개소)": "Manufacture and delivery of FRP wastewater treatment facilities (3 locations)",
      "이본건설㈜": "Ibon Construction Co., Ltd.",
      "오수처리장 반응조 포기배관 제작 및 설치공사": "Manufacture and installation of aeration pipes for the reaction tank at the wastewater treatment plant",
      "㈜셀럽골프앤리조트": "Celeb Golf & Resort Co., Ltd.",
      "4동 지하1층 집수정 등 오수 배출 펌프 교체 공사": "Replacement of sewage discharge pumps in sump pit on basement floor of Building 4",
      "㈜경기테크노파크": "Gyeonggi Technopark Co., Ltd.",
      "이길리 집단이주마을 재해복구사업(토목)_복합하수처리장비": "Disaster recovery project for Igil-ri collective relocation village (civil engineering)_Complex sewage treatment equipment",
      "강원도 철원군": "Cheorwon-gun, Gangwon-do",
      "농협대학교 하수처리시설 개선공사": "Improvement of wastewater treatment facility at Nonghyup University",
      "농협대학교": "Nonghyup University",
      "세차장 폐수처리시설 공사": "Construction of car wash wastewater treatment facility",
      "주식회사 진원": "Jinwon Co., Ltd.",
      "21-대미-수원비행장 창고 시설공사 중 정화조공사": "Septic tank construction within warehouse facility project at 21st Air Defense Missile Command Suwon Air Base",
      "요진건설산업": "Yojin Construction Industry",
      "동일면 대영, 봉남마을 하수도 정비사업": "Sewer maintenance project in Daeyoung and Bongnam villages, Dongil-myeon",
      "㈜에스오씨": "SOC Co., Ltd.",
      "대산산업용수 방류수 TOC 안정화사업 기본 및 실시설계 용역": "Basic and detailed design service for the stabilization project of TOC in the effluent of Daesan industrial water",
      "㈜동해종합기술공사": "Donghae Comprehensive Engineering Co., Ltd.",
      "국군 포천병원 오폐수처리시설 개수공사 (시운전 포함)": "Repair work of wastewater treatment facility at Armed Forces Pocheon Hospital (including test operation)",
      "주흥환경㈜": "Jooheung Environment Co., Ltd.",
      "비전 소규모 공공하수처리시설 개량사업중 기계설비공사": "Mechanical equipment work for the improvement project of small public sewage treatment facilities in Vision",
      "남원시환경사업소": "Namwon City Environmental Business Office",
      "고흥군 대영, 봉남 지구 농어촌 마을 하수도 정비사업 기술사용 협약서": "Technical usage agreement for sewer maintenance project in Daeyoung and Bongnam districts, Goheung-gun",
      "고흥군": "Goheung-gun",
      "(21-신영-10)군자폐수처리시설설치": "(21-Shinyoung-10) Installation of Gunja wastewater treatment facility",
      "공군 작사예하 방포사예하 제3여": "3rd Battalion, Air Force Logistics Command",
      "D부대 오수처리시설공사": "Wastewater treatment facility construction at D unit",
      "제1891부대": "Unit 1891",
      "D-3부대 오수처리시설공사": "Wastewater treatment facility construction at D-3 unit",
      "서울-문산고속도로 건설공사 1공구 일반건설공사 중 오수처리시설공사": "Wastewater treatment facility construction within general construction work of section 1 of Seoul-Munsan Expressway construction",
      "㈜에스엠 디자인": "SM Design Co., Ltd.",
      "역탑, 신양, 대천공공하수처리시설 기계설비 보완 공사": "Mechanical equipment supplementation work for public sewage treatment facilities in Yeoktap, Shinyang, and Daecheon",
      "예산군": "Yesan-gun",
      "은산산업단지 폐수종말처리시설 개보수 공사": "Renovation work of wastewater final treatment facility in Eunsan Industrial Complex",
      "신성설비 주식회사": "Shinsung Facilities Co., Ltd.",
      "자화전자㈜ 본사내 오수처리시설 150m3/day 설치공사": "Installation of 150m3/day wastewater treatment facility at Jahwa Electronics Co., Ltd. headquarters",
      "중경산업㈜": "Jungkyung Industries Co., Ltd.",
      "세계유교선비문화공원 하수처리시설 설치공사 공법기자재": "Installation of sewage treatment facilities at World Confucian Scholar Cultural Park",
      "경상북도개발공사": "Gyeongsangbuk-do Development Corporation",
      "국립익산박물관 건립공사 오수처리시설": "Sewage treatment facility construction for the National Iksan Museum construction project",
      "㈜송림": "Songrim Co., Ltd.",
      "한반도통일미래센터 제2직원숙소 오수처리시설": "Wastewater treatment facility for the 2nd staff accommodation at the Korean Peninsula Unification Future Center",
      "경호종합건설㈜": "Gyeongho General Construction Co., Ltd.",
      "국립익산박물관 건립공사 빗물처리시설": "Rainwater treatment facility for the National Iksan Museum construction project",
      "㈜아쿠아텍": "Aquatech Co., Ltd.",
      "액비저장조(149톤 4기, 134톤 1기)설치공사": "Installation of liquid fertilizer storage tanks (4 units of 149 tons, 1 unit of 134 tons)",
      "농부네아침 영농조합법인": "Farmer's Morning Agricultural Association",
      "당진시 동일교회 오수처리": "Wastewater treatment at Same Church in Dangjin City",
      "호수환경": "Hosoo Environment",
      "관작농공단지 정수시설 정비공사": "Maintenance work for water treatment facilities at Gwanzak Agricultural Industrial Complex",
      "호곡지구 마을하수처리시설 설치공사 관급자재(공법자재) 구입": "Purchase of government-supplied materials (technical materials) for the installation of village sewage treatment facilities in Hogok district",
      "전북지방조달청(남원시)": "Jeonbuk Regional Procurement Office (Namwon City)",
      "영주 한국문화테마파크 건립현장내 오수처리시설 설치": "Installation of wastewater treatment facility at the construction site of Korea Culture Theme Park in Yeongju",
      "㈜그리너스": "Greener Co., Ltd.",
      "세차장 폐수처리시설 설치공사": "Installation of car wash wastewater treatment facility",
      "삼보개발 주식회사": "Sambo Development Co., Ltd.",
      "참아람 김포공장 오수처리시설 기계공사 및 배관공사": "Mechanical and piping work for wastewater treatment facility at Chamaram Gimpo Plant",
      "주식회사 참아람": "Chamaram Co., Ltd.",
      "대동지구 소규모 공공하수처리시설 EM-SBR반응조 유입분배장치 수선": "Repair of EM-SBR reaction tank inlet distribution device for small public sewage treatment facility in Daedong district",
      "대전지방조달청(예산군)": "Daejeon Regional Procurement Office (Yesan-gun)",
      "액비저장조(1200m3) 설치공사": "Installation of liquid fertilizer storage tank (1200m3)",
      "농영회사법인 하늘채 주식회사": "Sky채 Agricultural Corporation Co., Ltd.",
      "예산군 노인종합복지관 건립공사 관급자재(우수중수처리시설)": "Purchase of government-supplied materials (rainwater treatment facilities) for the construction of Yesan-gun Senior Welfare Center",
      "오수처리시설 분리막 교체 공사": "Replacement of membranes in wastewater treatment facilities",
      "리앤리 어드바이저스 주식회사": "Lee & Lee Advisors Co., Ltd.",
      "일광 오사 격납고 FRP오수처리시설공사": "FRP wastewater treatment facility construction at Ilgwang Osa hangar",
      "㈜유원인더스트리": "Yuwon Industry Co., Ltd.",
      "오수처리시설 펌프류 교체 및 드럼스크림 필터교체 공사": "Replacement of pumps and drum screen filters in wastewater treatment facilities",
      "한화호텔앤드리조트 ㈜태안사업본부": "Hanwha Hotels & Resorts Co., Ltd. Taean Business Division",
      "서울대학교 남부학술림 교육연구동 신축공사 중 오수처리시설공사": "Wastewater treatment facility construction during new construction of Education and Research Building at Seoul National University Southern Forest",
      "㈜송정종합건설": "Songjeong General Construction Co., Ltd.",
      "육군 파주 고양 병영시설 BTL사업 오수처리시설 A지역 개선공사": "Improvement work of wastewater treatment facilities in A area of Army Paju Goyang barracks facility BTL project",
      "공우이엔씨 주식회사": "Gongwoo ENC Co., Ltd.",
      "송리지구 농어촌마을 하수도정비공사(2차)": "Second phase of sewer maintenance work in rural village of Songri district",
      "대구지방조달청(안동시)": "Daegu Regional Procurement Office (Andong City)",
      "안동 송리지구 농어촌마을하수도 정비사업": "Rural village sewer maintenance project in Songri district, Andong",
      "㈜대한테크": "Daehan Tech Co., Ltd.",
      "장암산업단지 내 오수처리시설 개보수": "Renovation of wastewater treatment facilities in Jangam Industrial Complex",
      "2017 광시 동산양수장 취입관로 설치사업": "2017 Installation project of intake pipes at Dongsan Pumping Station in Gwangsi",
      "오수처리시설 막 제작 및 설치 계약": "Contract for manufacture and installation of membranes in wastewater treatment facilities",
      "한화호텔앤드리조트㈜": "Hanwha Hotels & Resorts Co., Ltd.",
      "석문시장 재개발공사 중 오수처리시설공사": "Wastewater treatment facility construction within redevelopment work of Seokmun Market",
      "참아람 공장 오,폐수처리시설 및 비점오염저감시설": "Wastewater treatment facilities and non-point pollution reduction facilities at Chamaram Factory",
      "두원면 두원마을하수도 공법기자재 구매 제작 설치": "Purchase, manufacture, and installation of technical materials for sewer maintenance in Duwon-myeon, Duwon village",
      "군산 리버힐 관광호텔 오수처리장 개보수 공사": "Renovation of wastewater treatment plant at Gunsan Riverhill Tourist Hotel",
      "군산리버힐관광호텔": "Gunsan Riverhill Tourist Hotel",
      "2015 봉수산 자연휴향림보와사업(오수정화조)": "2015 Bongsusan Natural Recreational Forest Improvement Project (wastewater purification tank)",
      "임산물유통종합정보센터 오수처리시설공사": "Wastewater treatment facility construction at Forest Products Distribution Information Center",
      "산림조합중앙회 임산물유통센터": "National Forestry Cooperative Federation Forest Products Distribution Center",
      "슬라이딩센터 오수처리시설공사": "Wastewater treatment facility construction at Sliding Center",
      "대림산업㈜": "Daelim Industrial Co., Ltd.",
      "고부면 고부농촌마을하수도설치공사": "Sewer installation work in Gobu-myeon Gobu rural village",
      "(유)대신환경개발": "Daeshin Environmental Development Co., Ltd.",
      "대림씨엔에스㈜ 오수처리시설 개보수 공사": "Renovation work of wastewater treatment facilities at Daelim CNS Co., Ltd.",
      "SEWAGE TREATMENT PACKA": "Sewage Treatment Packa",
      "플러스원이엔씨㈜": "Plus One ENC Co., Ltd.",
      "신암농공단지 폐수처리시설 개보수공사": "Renovation work of wastewater treatment facilities in Shinam Agricultural Industrial Complex",
      "MEMBRANE 납품 및 교체 공사": "Delivery and replacement of membranes",
      "한화호텔&리조트": "Hanwha Hotels & Resorts",
      "화천지구 공공하수처리시설 설치사업": "Public sewage treatment facility installation project in Hwacheon district",
      "문막관광농원 오수처리시설 설치공": "Installation of wastewater treatment facilities at Munmak Tourist Farm",
      "마운틴관광농원": "Mountain Tourist Farm",
      "한반도통일미래센터건립공사(오수처리시설)": "Construction of Korean Peninsula Unification Future Center (wastewater treatment facilities)",
      "주문진제2농공단지비점오염처리시설": "Non-point pollution treatment facilities in Jumunjin 2nd Agricultural Industrial Complex",
      "폐수처리장": "Wastewater Treatment Plant",
      "하수처리장 설치 공사": "Sewage Treatment Plant Installation",
      "㈜현대건설(베트남)": "Hyundai Construction Co., Ltd. (Vietnam)",
      "㈜현대건설(알제리)": "Hyundai Construction Co., Ltd. (Algeria)",
      "㈜에덴은 대기 오염을 최소화하기 위한 다양한 기술과 솔루션을 제공하여": "Eden Co., Ltd. provides various technologies and solutions to minimize air pollution",
      "깨끗한 공기를 유지하는 데 기여하고 있습니다.": "contributing to maintaining clean air.",
      "우리는 첨단 대기 정화 기술을 적용하여": "We apply advanced air purification technology",
      "산업 현장에서 발생하는 다양한 오염 물질을 효율적으로 제거하고 있습니다.": "to efficiently remove various pollutants generated in industrial sites.",
      "소규모 사업장 방지시설 설치지원사업(흡수에 의한 시설, 여과집진시설)": "Small Business Prevention Facility Installation Support Project (Absorption Facilities, Filtration Dust Collectors)",
      "㈜미유켐테크": "Miucem Tech Co., Ltd.",
      "악취 및 VOCs 흡착시설 제작 및 설치공사": "Odor and VOCs Adsorption Facility Manufacturing and Installation",
      "에코비트 에너지 정세": "Ecobit Energy Jungse",
      "소규모 사업장 방지시설 설치지원사업 (여과집진장치)": "Small Business Prevention Facility Installation Support Project (Filtration Dust Collector)",
      "삼영산업㈜": "Samyoung Industry Co., Ltd.",
      "소규모 사업장 방지시설 설치지원사업(흡수에 의한 시설, 전기집진시설)": "Small Business Prevention Facility Installation Support Project (Absorption Facilities, Electrostatic Precipitators)",
      "대용물산": "Daeyong Trading",
      "활성탄여과기 제작, 설치, 시운전": "Activated Carbon Filter Manufacturing, Installation, and Commissioning",
      "예산 신암농공단": "Yesan Sinam Agricultural Industrial Complex",
      "소규모 사업장 방지시설 설치 지원사업(여과집진시설 및 흡착에의한시설)": "Small Business Prevention Facility Installation Support Project (Filtration Dust Collectors and Adsorption Facilities)",
      "금화기계": "Geumhwa Machinery",
      "지원사업(원심력집진시설, 여과집진시설)": "Support Project (Centrifugal Dust Collectors, Filtration Dust Collectors)",
      "신명산업": "Shinmyeong Industry",
      "소규모 사업장 방지시설 설치 지원사업(여과 및 흡착에 의한 시설)": "Small Business Prevention Facility Installation Support Project (Filtration and Adsorption Facilities)",
      "태극종합자동차정비": "Taeguk Comprehensive Auto Repair",
      "소규모 사업장 방지시설 설치지원사업(원심력집진시설,여과집진시설)": "Small Business Prevention Facility Installation Support Project (Centrifugal Dust Collectors, Filtration Dust Collectors)",
      "송운펠릿 주식회사": "Songun Pellet Co., Ltd.",
      "소규모 사업장 방지시설 설치지원사업(흡수에 의한 시설)": "Small Business Prevention Facility Installation Support Project (Absorption Facilities)",
      "디에스케미칼": "DS Chemical",
      "동광금속": "Dongkwang Metal",
      "2019년도 소규모 사업장 방지시설 설치 지원사업(여과집진시설, 흡착에의한 시설)": "2019 Small Business Prevention Facility Installation Support Project (Filtration Dust Collectors, Adsorption Facilities)",
      "한서포리머 주식회사": "Hanseo Polymer Co., Ltd.",
      "오룡지구 바이오필터 탈취기 설치공사": "Biofilter Deodorizer Installation in Oryong District",
      "㈜에스엠코리아": "SM Korea Co., Ltd.",
      "가축분뇨 공동자원화 개보수 지원사업(기계)": "Livestock Manure Joint Resource Recovery Renovation Support Project (Machinery)",
      "농업회사법인 하늘채 주식회사": "Sky채 Agricultural Corporation Co., Ltd.",
      "소규모 사업장 대기오염방지지설 설치 시범사업(송풍기, 순환펌프 교체)": "Small Business Air Pollution Prevention Facility Installation Pilot Project (Blower, Circulation Pump Replacement)",
      "현대도금": "Hyundai Plating",
      "서울비행장 방지시설 제작 및 설치공사": "Manufacturing and Installation of Prevention Facilities at Seoul Airfield",
      "제15특수임무비행단": "15th Special Mission Flight Group",
      "18-공-방부관리정비고(4049)도장부스 및 방지시설 설치공사": "18-Gong-Preservation Management Maintenance Warehouse (4049) Paint Booth and Prevention Facility Installation",
      "DM종합건설㈜": "DM General Construction Co., Ltd.",
      "long bag filter 실규모 실증설비 설치공사": "Long Bag Filter Large-Scale Demonstration Facility Installation",
      "㈜한빛파워(포스코 광양)": "Hanbit Power Co., Ltd. (POSCO Gwangyang)",
      "해남자연순환센터 공동자원화시설 개보수공사": "Haenam Natural Circulation Center Joint Resource Recovery Facility Renovation",
      "해남자연순환농업센터 영농조합법인": "Haenam Natural Circulation Agricultural Center Agricultural Cooperative Corporation",
      "LQ-1,2호기 덕트 및 후드 보수": "LQ-1,2 Duct and Hood Repair",
      "㈜서진오토모티브": "Seojin Automotive Co., Ltd.",
      "활성탄 교체공사": "Activated Carbon Replacement",
      "공군서산지역시설공사 대기오염방지설비(방부처리시설)": "Air Pollution Prevention Facility (Preservation Facility) Construction in the Air Force Seosan Area",
      "쌍용건설 주식회사": "Ssangyong Construction Co., Ltd.",
      "광시 소규모하수처리시설 탈취설비수선": "Gwangsi Small-Scale Sewage Treatment Facility Deodorization Equipment Repair",
      "계촌지구 소규모 공공하수처리시설 반응조 바이오휠 수선": "Gyechon District Small-Scale Public Sewage Treatment Facility Reactor Bio Wheel Repair",
      "예산군 분뇨처리시설 악취개선사업(기계) 관급자재": "Yesan County Manure Treatment Facility Odor Improvement Project (Machinery) Government-Supplied Materials",
      "신양지구 하수처리시설 설치사업 중 기계공사": "Mechanical Construction of Shinyang District Sewage Treatment Facility Installation Project",
      "유진건설㈜": "Eugene Construction Co., Ltd.",
      "성남 서울공항(14-지상장비고등시설공사)": "Seongnam Seoul Airport (14-Ground Equipment Storage Facility Construction)",
      "주식회사 엔테크 건설": "Entech Construction Co., Ltd.",
      "폐열회수재이용시설 설치공사": "Waste Heat Recovery Facility Installation",
      "삼호물산": "Samho Trading",
      "회수기 보수공사": "Recycler Repair",
      "후평동중계펌프장탈취기제작및설치공사": "Hupyeong-dong Relay Pump Station Deodorizer Manufacturing and Installation",
      "㈜태성종합건설": "Taeseong General Construction Co., Ltd.",
      "강릉비행장 오염방지 시설공사": "Gangneung Airfield Pollution Prevention Facility Construction",
      "선원건설㈜": "Sunwon Construction Co., Ltd.",
      "원주 공군시설 대기오염 방지시설 설치공사(방부처리장 급/배기 후드)": "Wonju Air Force Facility Air Pollution Prevention Facility Installation (Preservation Facility Supply/Exhaust Hood)",
      "휴먼택 코리아": "HumanTech Korea",
      "예천 공군부대 대기오염방지장치 제작 및 설치공사(도장부스)": "Yecheon Air Force Base Air Pollution Prevention Device Manufacturing and Installation (Paint Booth)",
      "양우건설㈜": "Yangwoo Construction Co., Ltd.",
      "사천공군비행장 도장부스 급배기 장치 및 방지시설 설치공사": "Sacheon Air Force Base Paint Booth Supply and Exhaust Device and Prevention Facility Installation",
      "온고올 건축": "Ongoall Construction",
      "여과집진장치 설치 공사": "Filtration Dust Collector Installation",
      "㈜고려인더스트리(러시아)": "Korea Industry Co., Ltd. (Russia)",
      "㈜에덴은 토목공사업에서 다년간의 경험과 전문성을 보유하고 있습니다.": "Eden Co. has many years of experience and expertise in civil engineering.",
      "우리는 고품질의 토목공사 서비스를 제공하여 인프라 개선 및 도시 개발에 기여합니다.": "We provide high-quality civil engineering services to contribute to infrastructure improvement and urban development.",
      "구립한울 어린이집 그린리모델링 공사": "Green Remodeling Project for Hanul Public Nursery",
      "서울특별시 동대문구": "Dongdaemun-gu, Seoul",
      "구립누리보듬 어린이집 그린리모델링 공사": "Green Remodeling Project for NuriBodeum Public Nursery",
      "불광천 미디어센터 옥외 환경정비 공사": "Outdoor Environment Improvement for Bulkwangcheon Media Center",
      "서울특별시 은평구": "Eunpyeong-gu, Seoul",
      "반지하주택(3,4단계) 개폐식방법창 설지 지원사업 공사(권역2)": "Project for Installation of Movable Windows in Semi-Basement Houses (Phase 3, 4) (Region 2)",
      "서울특별시 송파구": "Songpa-gu, Seoul",
      "평택시 용이보도육교 보수공사": "Repair Work for Yongyi Pedestrian Overpass in Pyeongtaek",
      "경기도 평택시": "Pyeongtaek-si, Gyeonggi-do",
      "안말공영주차장 화장실 설치사업 기계설비공사": "Mechanical Equipment Work for Installation of Restroom in Anmal Public Parking Lot",
      "서울특별시 강동구": "Gangdong-gu, Seoul",
      "광일중 교사동 화장실 개선공사": "Restroom Improvement Work for Teacher's Building at Gwangil Middle School",
      "경기도 교육청, 경기도광주하남 교육지원청": "Gyeonggi-do Office of Education, Gwangju Hanam Office of Education",
      "미래관 노후 시스템 냉난방기 철거공사": "Demolition Work of Old HVAC System in Future Hall",
      "서울특별시 서울시립대학교": "University of Seoul, Seoul",
      "청담고등학교 냉난방개선 기계공사": "HVAC Improvement Mechanical Work at Cheongdam High School",
      "경기도교육청 청담고등학교": "Cheongdam High School, Gyeonggi-do Office of Education",
      "사격장 표적호 및 법면보강공사": "Target Pit and Slope Reinforcement Work at Shooting Range",
      "제7297부대": "Unit 7297",
      "00부대 여성필수시설 보수": "Maintenance of Essential Facilities for Women at Unit 00",
      "제7군단": "7th Corps",
      "2022년 사립학교 교육환경개선사업 교내 배수로 교체공사 (토목공사)": "Replacement Work of Drainage System in Private Schools for Education Environment Improvement Project (Civil Engineering)",
      "경기도교육청 율곡고등학교": "Yulgok High School, Gyeonggi-do Office of Education",
      "현덕면 덕목리 919-2번지 일원 용수로 정비공사": "Waterway Maintenance Work in Deokmok-ri, Hyundeok-myeon",
      "경기도 평택시 농업기술센터": "Pyeongtaek Agricultural Technology Center, Gyeonggi-do",
      "두정2리(소로2-15호선) 도시계획도로개설공사(2구간),(2차분)": "Urban Planning Road Construction Work (2nd Section) in Dujung 2-ri (Road Line 2-15) (2nd Part)",
      "성신 다빈치": "Sungsin Da Vinci",
      "수도권제1순환선 시흥하늘휴게소 부체도로 개량공사(2차)": "Improvement Work for Auxiliary Road at Siheung Sky Rest Area on 1st Ring Road of Capital Region (2nd Part)",
      "한국도로공사": "Korea Expressway Corporation",
      "고덕면 문곡리 192-2번지 일원용/배수로 정비공사": "Water and Drainage Way Maintenance Work in Mungok-ri, Godeok-myeon",
      "2022년 사립학교 교육환경개선사업 교내 배수로": "Drainage System in Private Schools for Education Environment Improvement Project in 2022",
      "자연유하 정화조 하수악취 저감 실증조사 용역": "Demonstration Service for Reducing Sewage Odor in Natural Flow Septic Tanks",
      "서울특별시": "Seoul Metropolitan Government",
      "두정2리(소로2-15호선)도시계획도로개설공사(2구간)": "Urban Planning Road Construction Work (2nd Section) in Dujung 2-ri (Road Line 2-15)",
      "인쇄소 휘발성유기화합물(VOCs)저감시설 시범설치 및 저감방안 학술용역": "Pilot Installation and Research Service for Reducing VOCs in Printing Shops",
      "고덕면 문곡리 192-2번지 일원용 배수로 정비공사": "Drainage Way Maintenance Work in Mungok-ri, Godeok-myeon",
      "홍원1리 배수로 정비공사": "Drainage Way Maintenance Work in Hongwon 1-ri",
      "경기도 평택시 포승읍": "Poseung-eup, Pyeongtaek-si, Gyeonggi-do",
      "송현초 노후 냉난방 개선 기계설비공사": "HVAC Improvement Mechanical Work at Songhyun Elementary School",
      "경기도 교육청, 경기도평택 지원청": "Gyeonggi-do Office of Education, Pyeongtaek Support Office",
      "서부여성발전센터 수영장 여과기 제작 설치 및 부대설비 교체 공사": "Manufacturing and Installation of Swimming Pool Filter and Replacement of Auxiliary Facilities at Western Women's Development Center",
      "서울시 서부여성발전센터": "Seoul Western Women's Development Center",
      "2020년 청오지소 수초제거 사업": "2020 Aquatic Plant Removal Project at Cheong-o Branch",
      "한국농어촌공사 평택지사": "Korea Rural Community Corporation Pyeongtaek Branch",
      "청오지구 용배수로 퇴적물제거사업": "Sediment Removal Project for Water and Drainage Way in Cheong-o District",
      "2019학년도 예산유치원 교육환경개선 공사": "2019 Educational Environment Improvement Work at Yesan Kindergarten",
      "덕산 공공하수처리시설 BY-PASS 배관변경 공사": "BY-PASS Pipe Change Work at Deoksan Public Sewage Treatment Facility",
      "노후계량기 교체사업": "Old Water Meter Replacement Project",
      "계룡시 상하수도사업소장": "Head of Water and Sewage Office, Gyeryong-si",
      "대전지방조달청(부여군)" : "Daejeon Regional Public Procurement Service (Buyeo County)",
      "부여군" : "Buyeo County",
      "상하수도 설비공사업" : "Water and Sewage Facility Construction",
      "회사 설립": "Company established",
      "생물학적 필터를 이용한 약취 및 휘발성 물질의 제거방법에 관한 기술협약 체결": "Technology agreement on the method for removing odor and volatile substances using a biological filter",
      "오, 폐수 처리용 침지식 정밀여과 평막모듈에 관한 기술협약 체결": "Technology agreement on the submerged precision filtration flat membrane module for wastewater treatment",
      "환경오염방지시설 원격관리 시스템에 관한 기술협약 체결": "Technology agreement on remote management system for environmental pollution prevention facilities",
      "멸균수 세척에 의한 공기청정기 개발": "Development of air purifier using sterilized water washing",
      "EM(Eco-Mecanism)-System을 이용한 하, 폐수 고도처리시설 개발 착수": "Started development of advanced wastewater treatment facility using EM (Eco-Mecanism) System",
      "한국산업안전공단 클린사업 설비업체 등록": "Registered as a facility company for the Clean Business by the Korea Occupational Safety and Health Agency",
      "무산소/호기 및 슬러지 블랭킷을 이용한 하수 고도처리기술-AOSB(환경신기술지정 56호) 기술 계약": "Technology contract for advanced sewage treatment using anaerobic/aerobic and sludge blanket - AOSB (Environmental New Technology Designation No. 56)",
      "식물토양층을 이용한 오, 하수 자연 정화법(특허 제0458564호) 기술 계약 체결": "Technology contract for natural purification method of wastewater using plant soil layer (Patent No. 0458564)",
      "EM(Eco-Mecanism)-System을 이용한 하, 폐수 고도처리시설 실증 플랜트 가동": "Operation of demonstration plant for advanced wastewater treatment facility using EM (Eco-Mecanism) System",
      "질소가스를 이용한 VOCs 회수 시스템 특허 출원": "Patent application for VOCs recovery system using nitrogen gas",
      "EM-System을 이용한 하수고도처리시설 특허 출원": "Patent application for advanced sewage treatment facility using EM-System",
      "메탄가스를 이용한 발전 시스템 특허 출원": "Patent application for power generation system using methane gas",
      "미생물을 이용한 악취 및 폐가스 정화처리장치 실용 및 특허 출원": "Utility and patent application for odor and waste gas purification device using microorganisms",
      "생물학적 여재를 이용한 오폐수처리장치 (특허 제10-0745444호)": "Wastewater treatment device using biological filter media (Patent No. 10-0745444)",
      "오수처리시설 등 설계, 시공업 등록 (예산 제8호)": "Registered as design and construction business for sewage treatment facilities (Yesan No. 8)",
      "충남 예산군 예산리 509-3 3층 (지사 설립)": "Established branch office at 509-3 Yesan-ri, Yesan-gun, Chungnam, 3rd floor",
      "오폐수 처리수용 자외선 램프의 세척장치 특허 출원 (특허 제07-92259호)": "Patent application for UV lamp cleaning device for wastewater treatment (Patent No. 07-92259)",
      "컨베이어 시스템의 링크체인, 슈트 및 파이프라인과 링크체인 콘베이어 시스템 (특허 제07-48741)": "Link chain, chute, and pipeline for conveyor system and link chain conveyor system (Patent No. 07-48741)",
      "상징수 배출용 디켄터 이동장치 (특허 제07-99792호)": "Decanter moving device for symbolic water discharge (Patent No. 07-99792)",
      "러시아 여과집진장치 설치 공사": "Installation of filtration and dust collection device in Russia",
      "환경과기술에서 주식회사 에덴으로 상호 변경": "Changed company name from Environment and Technology to Eden Co., Ltd.",
      "가축분뇨 처리시설 설계, 시공업 등록 (예산 제8호)": "Registered as design and construction business for livestock manure treatment facilities (Yesan No. 8)",
      "개인하수처리시설 관리업 등록 (예산 2008-5호)": "Registered as private sewage treatment facility management business (Yesan 2008-5)",
      "베트남 하수처리장 공법 수출": "Exported sewage treatment method to Vietnam",
      "경기도 평택 공장 준공": "Completed construction of factory in Pyeongtaek, Gyeonggi-do",
      "오, 폐수 처리수용 자외선 램프의 세척장치 특허 (제10-0897570호) - UV 소독기 개발": "Patent for UV lamp cleaning device for wastewater treatment (No. 10-0897570) - Developed UV sterilizer",
      "상징수 배출용 디켄터 이동장치 특허 등록 (제10-0925502호)": "Patent registration for decanter moving device for symbolic water discharge (No. 10-0925502)",
      "도장부스의 환기시스템 특허 등록 (제10-1094977호)": "Patent registration for ventilation system of paint booth (No. 10-1094977)",
      "하폐수 처리수 재이용 장치 빔 방법 특허 등록 (제10-1129292호)": "Patent registration for beam method device for reuse of wastewater treatment water (No. 10-1129292)",
      "공주대 산학연협력 공동기술개발 실시 (EM-SBR)": "Joint technology development with Kongju National University for industry-academia cooperation (EM-SBR)",
      "변기의 원심배출장치 (제10-1199799호)": "Centrifugal discharge device for toilet (No. 10-1199799)",
      "코엑스 환경전시회 참여": "Participated in COEX Environmental Exhibition",
      "상하수도 설비 공사업 등록": "Registered as water supply and drainage construction business",
      "공주대 산학연협력 공동기술개발 완료 (EM-SBR)": "Completed joint technology development with Kongju National University for industry-academia cooperation (EM-SBR)",
      "하, 폐수의 인 농축 회수 장치 밒 방법 특허 등록 (제10-1256821호)": "Patent registration for phosphorus concentration recovery device and method for wastewater (No. 10-1256821)",
      "유입가스분배기를 이용한 생물학적 악취 처리시설 특허 등록 (제10-1249291호)": "Patent registration for biological odor treatment facility using inlet gas distributor (No. 10-1249291)",
      "패키지형 상수도 고도정수처리시설 특허 등록 (제10-1300438호)": "Patent registration for package-type advanced water treatment facility (No. 10-1300438)",
      "초기우수 제어 및 비점오염원 처리장치와 그 처리방법 특허 등록 (제1388994호)": "Patent registration for initial rainwater control and non-point pollution source treatment device and method (No. 1388994)",
      "알제리 하수처리 공법 수출": "Exported sewage treatment method to Algeria",
      "(주)에덴 기업부설연구소 서울 사무실 (등록 제20140162호)": "Eden Co., Ltd. corporate research institute Seoul office (Registration No. 20140162)",
      "한반도통일미래센터 건립공사(오수처리시설) (중경산업)": "Construction of Hanbando Unification Future Center (sewage treatment facility) (Jung Kyung Industry)",
      "(주)에덴 벤처기업 등록 (등록 제2015010817호)": "Eden Co., Ltd. registered as a venture company (Registration No. 2015010817)",
      "공주대학교 산학연협력 공동기술개발 실시 (EM-SBR Ⅱ) (아질산화-아탈질을 이용한 질소/인 동시제거 SBR System 개발)": "Joint technology development with Kongju National University for industry-academia cooperation (EM-SBR Ⅱ) (Development of nitrogen/phosphorus simultaneous removal SBR system using nitritation-denitritation)",
      "환경전문공사업(대기) 등록 (면허번호 충남-182호)": "Registered as an environmental professional construction business (air) (License No. Chungnam-182)",
      "펌프 가압수의 압착력에 의한 하폐수 방류수 내의 부유물질 여과장치 특허등록 (제10-1631385호)": "Patent registration for suspended solids filtration device in wastewater discharge using pump pressurized water compression (No. 10-1631385)",
      "하, 폐수 처리장의 자동청소 스크린장치 특허 출원 (10-2016-0098614)": "Patent application for automatic cleaning screen device in wastewater treatment plant (No. 10-2016-0098614)",
      "미세먼지 제어 및 운영방안에 관한 용역사업 (환경부)": "Service project on fine dust control and management plan (Ministry of Environment)",
      "예산군 분뇨처리시설 악취개선사업 (대전지방조달청(예산군))": "Odor improvement project for manure treatment facility in Yesan-gun (Daejeon Regional Procurement Service (Yesan-gun))",
      "시설물 유지관리업 등록": "Registered as facility maintenance business",
      "공군서산지역시설공사 대기오염방지설비(방부처리시설) (쌍용건설)": "Air pollution prevention facility for Air Force Seosan area construction (preservative treatment facility) (Ssangyong Construction)",
      "해외플랜트 시장 개척 지원사업 (한국플랜트산업협회)": "Support project for overseas plant market development (Korea Plant Industry Association)",
      "서울비행장 방지시설 제작 및 설치공사 (공군 제15특수임무비행단)": "Manufacture and installation of prevention facilities at Seoul Airfield (Air Force 15th Special Mission Wing)",
      "미세먼지 배출저감 및 위험회피 R&D 및 실증 (서울에너지공사)": "Fine dust emission reduction and risk avoidance R&D and demonstration (Seoul Energy Corporation)",
      "서부여성발전센터 수영장 여과기 제작 설치 및 부대설비 교체공사 (서울시 서부여성발전센터)": "Manufacture and installation of swimming pool filter at Western Women's Development Center and replacement of auxiliary facilities (Seoul Western Women's Development Center)",
      "토목공사업 등록": "Registered as civil engineering construction business",
      "악취 및 VOCs 흡착시설 제작 및 설치공사 (에코비트 에너지 정세)": "Manufacture and installation of odor and VOCs adsorption facilities (Ecobit Energy Jeongse)",
      "체인형 회전체를 이용한 VOCs 농축 제거 시스템 특허 등록": "Patent registration for VOCs concentration removal system using chain-type rotating body",
      "국군 포천병원 오폐수처리시설 개수공사 (주흥환경)": "Improvement work for wastewater treatment facility at Pocheon Military Hospital (Juhung Environment)",
      "이길리 집단이주마을 재해복구사업 하수처리장비 (강원도 철원군)": "Disaster recovery project for collective relocation village in Igil-ri - sewage treatment equipment (Cheorwon-gun, Gangwon-do)",
      "무안 항공특화산업단지 조성공사 (주식회사 예진종합건설)": "Construction of Muan Aviation Specialized Industrial Complex (Yejin Construction Co., Ltd.)",
      "업데이트 예정": "Update scheduled",
      "2002년~2010년": "2002 Year ~ 2010 Year",
      "2011년~2020년": "2011 Year ~ 2020 Year",
      "2021년~현재": "2021 Year ~ Current",
      "년": " Year",
      "(주)에덴": "Eden Co., Ltd.",
      "㈜에덴": "Eden Co., Ltd.",
      ".하, 폐수의 인 농축 회수 장치 및 방법 특허 등록 (제10-1256821호)": "Patent Registration for Device and Method for Concentrating and Recovering Phosphorus from Wastewater (No. 10-1256821)",
      "㈜에덴은 고객의 요구사항을 충족시키는 맞춤형 수처리시설 설계를 제공합니다.": "Eden Co., Ltd. provides customized water treatment facility designs to meet customer requirements.",
      "우리 회사는 최신 기술과 다양한 경험을 바탕으로 최적의 설계 솔루션을 제시하여": "Our company presents optimal design solutions based on the latest technology and diverse experiences",
      "고객의 수질 문제를 효과적으로 해결합니다.": "to effectively solve customers' water quality issues.",
      "맞춤형 설계 솔루션 제공": "Providing Customized Design Solutions",
      "최신 기술 도입": "Introducing the Latest Technology",
      "효율적이고 경제적인 설계": "Efficient and Economical Design",
      "환경 규제 준수": "Compliance with Environmental Regulations",
      "철저한 현장 관리": "Thorough On-site Management",
      "최신 시공 기술 적용": "Application of Latest Construction Technology",
      "환경 친화적 시공 방법": "Environmentally Friendly Construction Methods",
      "고객 맞춤형 시공 서비스": "Customer-tailored Construction Services",
      "㈜에덴은 수처리시설의 효율적이고 지속 가능한 운영을 위해": "Eden Co., Ltd. provides systematic management services for the efficient and sustainable operation of water treatment facilities.",
      "체계적인 관리 서비스를 제공합니다. 정기적인 점검, 유지보수 및 문제 해결을 포함하여": "We offer systematic management services including regular inspections, maintenance, and problem-solving",
      "고객의 수처리시설이 항상 최상의 상태를 유지할 수 있도록 지원합니다.": "to ensure that your water treatment facilities are always in optimal condition.",
      "정기적인 점검 및 유지보수": "Regular Inspections and Maintenance",
      "신속한 문제 해결": "Rapid Problem Resolution",
      "운영 효율성 극대화": "Maximized Operational Efficiency",
      "고객 맞춤형 관리 서비스": "Customized Management Services",
      "㈜에덴은 다양한 대기오염 방지시설 설계를 통해 깨끗한 대기 환경을 조성합니다.": "Eden Co., Ltd. creates a clean air environment through the design of various air pollution control facilities.",
      "우리는 최신 기술을 바탕으로 맞춤형 설계 솔루션을 제공하여 고객의 요구를 충족시킵니다.": "We provide customized design solutions based on the latest technology to meet the needs of our customers.",
      "㈜에덴은 대기오염 방지시설의 고품질 시공 서비스를 제공합니다.": "Eden Co., Ltd. provides high-quality construction services for air pollution control facilities.",
      "철저한 현장 관리와 최신 시공 기술을 통해": "Through thorough site management and the application of the latest construction technologies,",
      "안전하고 효율적인 대기오염 방지시설을 구축합니다.": "we build safe and efficient air pollution control facilities.",
      "㈜에덴은 대기오염 방지시설의 효율적이고 지속 가능한 운영을 위해": "Eden Co., Ltd. provides systematic management services for the efficient and sustainable operation of air pollution control facilities.",
      "고객의 대기오염 방지시설이 항상 최상의 상태를 유지할 수 있도록 지원합니다.": "to ensure that our clients' air pollution control facilities are always in optimal condition.",
      "㈜에덴은 토목공사업에서 다년간의 경험과 전문성을 보유하고 있습니다. 우리는 고품질의 토목공사 서비스를 제공하여 인프라 개선 및 도시 개발에 기여합니다.": "Eden Co., Ltd. has years of experience and expertise in civil engineering. We provide high-quality civil engineering services that contribute to infrastructure improvement and urban development.",
      "인프라 개선": "Infrastructure Improvement",
      "도시 개발 지원": "Urban Development Support",
      "최신 토목 기술 적용": "Application of Latest Civil Engineering Technology",
      "㈜에덴은 상하수도 설비공사업에서 뛰어난 기술력과 풍부한 경험을 보유하고 있습니다.": "Eden Co., Ltd. has outstanding technology and rich experience in water and sewerage construction.",
      "우리는 다양한 프로젝트를 통해 고품질의 상하수도 설비를 구축하여": "We build high-quality water and sewerage facilities through various projects",
      "깨끗한 물 공급과 위생적인 하수 처리를 보장합니다.": "to ensure the supply of clean water and sanitary sewage treatment.",
      "깨끗한 물 공급": "Clean Water Supply",
      "위생적인 하수 처리": "Sanitary Sewage Treatment",
      "최신 설비 기술 적용": "Application of Latest Equipment Technology",
      "㈜에덴은 기계설비공사업에서 고품질의 설비 시공 서비스를 제공합니다.": "Eden Co., Ltd. provides high-quality equipment construction services in the field of mechanical equipment construction.",
      "우리는 최신 기계 설비 기술을 바탕으로 안정적이고 효율적인 설비를 구축하여": "Based on the latest mechanical equipment technology, we build stable and efficient facilities",
      "고객의 생산성과 효율성을 향상시킵니다.": "to improve customer productivity and efficiency.",
      "최신 기계 설비 기술 적용": "Application of Latest Mechanical Equipment Technology",
      "안정적이고 효율적인 설비 구축": "Stable and Efficient Facility Construction",
      "㈜에덴은 고품질의 수처리시설 시공 서비스를 제공합니다.":"Eden Co., Ltd. provides high-quality water treatment facility construction services.",
      "우리의 전문 팀은 철저한 현장 관리와 시공 기술을 통해":"Our expert team ensures thorough site management and construction techniques",
      "안정적이고 지속 가능한 수처리시설을 구축합니다.":"to build stable and sustainable water treatment facilities.",
    }
  },
  ko: {
    translation: {
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko",
    fallbackLng: 'ko',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
